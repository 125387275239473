// import logo from './logo.svg';
import React from "react"
import './App.css';
import axios from 'axios';
import superagent from 'superagent';


const SERVER = 'http://91.201.113.238:3001'

const sendPostRequest = async (url, data) => {
  return superagent.post(SERVER + url, data).then(r => {
    console.log('sendPostRequest', r)
    return r.body
  })
}

class App extends React.Component {
  state = {
    csv: '',
    csvPrompt: '',
    csvResponse: '',

    regularPrompt: '',
    regularResponse: '',
  }


  handleChange = () => ev => {
    const formData = new FormData();
    // files.forEach((file, index) => {
    // });
    const pretty = v => JSON.stringify(v, null, 2)
    const file = ev.target.files[0]
    formData.append(`file${0}`, file);
    // console.log(file, JSON.stringify(file, null, 2))
    console.log(formData)

    let reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => {
      const text = reader.result
      console.log(text);
      this.setState({csv: text})
    };

    reader.onerror = () => {
      console.log(reader.error);
    };
    // setFile(event.target.files[0])
  }

  onCsvRequestEdit = () => ev => {
    this.setState({csvPrompt: ev.target.value})
  }

  onNormalRequestEdit = () => ev => {
    this.setState({regularPrompt: ev.target.value})
  }

  onNormalRequestSubmit = () => ev => {
    const regularPrompt = this.state.regularPrompt

    sendPostRequest('/prompts/normal', {
      regularPrompt
    })
      .then((response) => {
        const text = response.answer
        console.log(response);

        this.setState({regularResponse: text})
      });
  }

  onSendCSV = () => ev => {
    sendPostRequest('/prompts/csv',{
      file: this.state.csv,
      csvPrompt: this.state.csvPrompt
    })
      .then((response) => {
        const text = response.answer
        console.log(response);

        this.setState({csvResponse: text})
      });
  }

  render() {
    const canSendFile = this.state.csv && this.state.csvPrompt
    const gptButton = canSendFile ? <button onClick={this.onSendCSV()}>Summarise text</button> : ''

    return (
      <div className="App">
        <header className="App-header">
          {/*<form id={"csvform"}>*/}
          <label>Paste CSV file here</label>
          <input id="filecsv" onChange={this.handleChange()} type="file"/>

          <br />
          <label>CSV prompt</label>
          {/*<p>You typed: ${this.state.csvPrompt}</p>*/}
          <input onInput={this.onCsvRequestEdit()} />
          {gptButton}

          <h2>File report</h2>
          <label>{this.state.csvResponse}</label>

          {/*</form>*/}

          <br />
          <br />
          <br />
          <hr />
          <label>Ask anything</label>
          <input onInput={this.onNormalRequestEdit()}/>
          <button onClick={this.onNormalRequestSubmit()}>Ask!</button>
          <label>ChatGPT response</label>
          <p>{this.state.regularResponse}</p>
        </header>
      </div>
    );
  }
}

export default App;
